/**
 * @file
 * Provides Slick loader.
 */
import { Splide } from "@splidejs/splide";
import { URLHash } from "@splidejs/splide-extension-url-hash";
import { AdjustableHeight } from "./splide-adjustable-height";

import "@splidejs/splide/css";

(($, context) => {
	"use strict";

	function doSplide(i, element) {
		let mounts = {};

		const config = JSON.parse(element.dataset.splide);

		const splide = new Splide(element);

		if (config?.hashNavigation) {
			mounts = { ...mounts, URLHash };
		}

    if (config?.autoHeight) {
			mounts = { ...mounts, AdjustableHeight };
		}

		const progressBar = splide.root.querySelector(
			".basejump_splide__progress-bar",
		);

		const counter = splide.root.querySelector(
			".basejump_splide__counter",
		);

		if (progressBar) {
			splide.on("mounted move", function () {
				const end = splide.Components.Controller.getEnd() + 1;
				const rate = Math.min((splide.index + 1) / end, 1);
				progressBar.style.width = String(100 * rate) + "%";
			});
		}

		if (counter) {
			splide.on("mounted move", function () {
				const end = splide.Components.Controller.getEnd() + 1;
				const index = splide.index + 1;
				counter.textContent = `${index} / ${end}`;
			});
		}

		splide.mount(mounts);

		if (config?.customNext) {
			document.addEventListener("click", (event) => {
				if (event.target.closest(config.customNext)) {
					splide.go(">");
				}
			});
		}

		if (config?.customPrevious) {
			document.addEventListener("click", (event) => {
				if (event.target.closest(config.customPrevious)) {
					splide.go("<");
				}
			});
		}
	}

	$(window).on("load ajaxComplete", () => {
		$(".splide").each(doSplide);
	});
})(jQuery, jQuery(document));
